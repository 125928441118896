import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "report",
  initialState: {
    location: 0,
    calendar: 0,
    cutsToday: 0,
    cutsYesterday: 0,
    plotters: 0,
    averageCut: 0,
    forcast: 0,
    mtd: 0,
    ytd: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    chartQ1: [],
    chartQ2: [],
    chartQ3: [],
    chartQ4: [],
    chartYear: [],
    countriesList: [],
    provincesList: [],
    citiesList: [],
    districtsList: [],
    importerList: [],
    distributorList: [],
    retailChainList: [],
    retailShopList: [],
    brandsList: [],
    modelsList: [],
    startDate: "",
    endDate: "",
    selectedCountryId: 0,
    selectedProvinceId: 0,
    selectedCityId: 0,
    selectedDistrictId: 0,
  },
  reducers: {
    reportLocation: (report, action) => {
      report.location = action.payload.location;
    },
    reportCalendar: (report, action) => {
      report.calendar = action.payload.calendar;
    },
    reportCutsToday: (report, action) => {
      report.cutsToday = action.payload.cutsToday;
    },
    reportCutsYesterday: (report, action) => {
      report.cutsYesterday = action.payload.cutsYesterday;
    },
    reportAverageCut: (report, action) => {
      report.averageCut = action.payload.averageCut;
    },
    reportPlotters: (report, action) => {
      report.plotters = action.payload.plotters;
    },
    reportForcast: (report, action) => {
      report.forcast = action.payload.forcast;
    },
    reportMtd: (report, action) => {
      report.mtd = action.payload.mtd;
    },
    reportYtd: (report, action) => {
      report.ytd = action.payload.ytd;
    },
    reportQ1: (report, action) => {
      report.q1 = action.payload.q1;
    },
    reportQ2: (report, action) => {
      report.q2 = action.payload.q2;
    },
    reportQ3: (report, action) => {
      report.q3 = action.payload.q3;
    },
    reportQ4: (report, action) => {
      report.q4 = action.payload.q4;
    },
    reportChartQ1: (report, action) => {
      report.chartQ1 = action.payload.chartQ1;
    },
    reportChartQ2: (report, action) => {
      report.chartQ2 = action.payload.chartQ2;
    },
    reportChartQ3: (report, action) => {
      report.chartQ3 = action.payload.chartQ3;
    },
    reportChartQ4: (report, action) => {
      report.chartQ4 = action.payload.chartQ4;
    },
    reportChartYear: (report, action) => {
      report.chartYear = action.payload.chartYear;
    },
  },
});

export const {
  reportLocation,
  reportCalendar,
  reportCutsToday,
  reportCutsYesterday,
  reportAverageCut,
  reportForcast,
  reportPlotters,
  reportMtd,
  reportYtd,
  reportQ1,
  reportQ2,
  reportQ3,
  reportQ4,
  reportChartQ1,
  reportChartQ2,
  reportChartQ3,
  reportChartQ4,
  reportChartYear,
} = slice.actions;
export default slice.reducer;
