import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "data",
  initialState: {
    countries: [],
    provinces: [],
    cities: [],
    districts: [],
    brands: [],
    devices: [],
    importerList: [],
    distributorList: [],
    retailChainList: [],
    retailShopList: [],
    channelId: 0,
    clientId: 0,
    brandId: 0,
    modelId: 0,
    startDate: "2020-12-01",
    endDate: "2020-12-21",
    quarter: 0,
    dayId: 0,
    sortObject: "",
    selectedPartType: 0,
  },
  reducers: {
    dataCountries: (data, action) => {
      data.countries = [];
      data.countries.push(...action.payload.countries);
    },
    dataProvinces: (data, action) => {
      data.provinces = [];
      if (action.payload.provinces !== []) {
        data.provinces.push(...action.payload.provinces);
      }
    },
    dataCities: (data, action) => {
      data.cities = [];
      if (action.payload.cities !== []) {
        data.cities.push(...action.payload.cities);
      }
    },
    dataDistricts: (data, action) => {
      data.districts = [];
      if (action.payload.districts !== []) {
        data.districts.push(...action.payload.districts);
      }
    },
    dataBrands: (data, action) => {
      data.brands = [];
      data.brands.push(...action.payload.brands);
    },
    dataDevices: (data, action) => {
      data.devices = [];
      data.devices.push(...action.payload.devices);
    },
    dataImporterList: (data, action) => {
      data.importerList = [];
      data.importerList.push(...action.payload.importerList);
    },
    dataDistributorList: (data, action) => {
      data.distributorList = [];
      data.distributorList.push(...action.payload.distributorList);
    },
    dataRetailChainList: (data, action) => {
      data.retailChainList = [];
      data.retailChainList.push(...action.payload.retailChainList);
    },
    dataRetailShopList: (data, action) => {
      data.retailShopList = [];
      data.retailShopList.push(...action.payload.retailShopList);
    },
  },
});

export const {
  dataCountries,
  dataProvinces,
  dataCities,
  dataDistricts,
  dataBrands,
  dataDevices,
  dataImporterList,
  dataDistributorList,
  dataRetailChainList,
  dataRetailShopList,
} = slice.actions;
export default slice.reducer;
