import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "user",
  initialState: {
    userId: 0,
    email: "",
    firstName: "",
    lastName: "",
    channel: "",
    companyName: "",
    fullName: "",
    channelId: 0,
    dayId: 0,
    timeZone: "",
  },
  reducers: {
    userAdded: (user, action) => {
      user.email = action.payload.email;
    },
    userUpdated: (user, action) => {
      user.userId = action.payload.user.id;
      user.firstName = action.payload.user.firstName;
      user.lastName = action.payload.user.lastName;
      user.fullName = action.payload.user.fullName;
      user.companyName = action.payload.user.companyName;
      user.channel = action.payload.user.channel;
      user.channelId = action.payload.user.channelId;
    },
    userSettingsUpdated: (user, action) => {
      user.dayId = action.payload.dayId;
      user.timeZone = action.payload.timeZone;
    },
  },
});

export const { userAdded, userUpdated, userSettingsUpdated } = slice.actions;
export default slice.reducer;
