import "bootstrap/dist/css/bootstrap.css";
import "./content/css/quix-icon.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import configureStore from "./Store/configureStore";
import StoreContext from "./context/storeContext.js";
import MetaTags from "react-meta-tags";
import { unregister } from "./registerServiceWorker";
import { lazy } from "@loadable/component";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import localStorage from "local-storage";


const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const store = configureStore();

const App = lazy(() => import("./App"));

i18next
  .init({
    fallbackLng: localStorage.get("usersLanguageKey") === null ? "en" : localStorage.get("usersLanguageKey"),
    resources: {
      en: {
        common: require("./i80n/en.json")
      },
      ru: {
        common: require("./i80n/ru.json")
      },
      in_3: {
        common: require("./i80n/in_3.json")
      }
    },
  });

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <StoreContext.Provider value={store}>
      <MetaTags>
        <title>QUIX API</title>
        <meta httpEquiv="Content-Security-Policy" />
        <meta name="description" content="Your description here.." />
        <meta property="og:title" content="QUIX API" />
        <meta httpEquiv='cache-control' content='no-cache' />
        <meta httpEquiv='expires' content='0' />
        <meta httpEquiv='pragma' content='no-cache' />
      </MetaTags>
      <Suspense fallback={
        <div style={{ marginLeft: "50%" }}>Loading...</div>}>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </Suspense>
    </StoreContext.Provider>
  </BrowserRouter>,
  rootElement
);

unregister();
