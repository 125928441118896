import { combineReducers } from "redux";
import reportReducer from "./report";
import dataReducer from "./data";
import filterReducer from "./filter";

export default combineReducers({
  report: reportReducer,
  data: dataReducer,
  filter: filterReducer
});
