import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "menu",
  initialState: { menuList: [] },
  reducers: {
    menuUpdated: (menu, action) => {
      menu.menuList = action.payload.menuList;
    },
  },
});

export const { menuUpdated } = slice.actions;

export default slice.reducer;
