import { createSlice } from "@reduxjs/toolkit";
import localStorage from "local-storage";

const slice = createSlice({
  name: "filter",
  initialState: {
    countryId: 0,
    provinceId: 0,
    cityId: 0,
    districtId: 0,
    clientId: 0,
    brandId: 0,
    modelId: 0,
    startDate:
      new Date().getFullYear() +
      "-" +
      ((new Date().getMonth() + 1).toString().length === 1 ? "0" : "") +
      (new Date().getMonth() + 1) +
      "-01",
    endDate:
      new Date().getFullYear() +
      "-" +
      ((new Date().getMonth() + 1).toString().length === 1 ? "0" : "") +
      (new Date().getMonth() + 1) +
      "-" +
      (new Date().getDate().toString().length === 1 ? "0" : "") +
      new Date().getDate(),
    quarter: 0,
    dayId: 0,
    timeZone: localStorage.get("timeZone"),
    sortObject: "",
    selectedPartType: 1,
    deviceType : 0
  },
  reducers: {
    filterSelectedCountry: (filter, action) => {
      filter.countryId = action.payload.countryId;
    },
    filterSelectedProvince: (filter, action) => {
      filter.provinceId = action.payload.provinceId;
    },
    filterSelectedCity: (filter, action) => {
      filter.cityId = action.payload.cityId;
    },
    filterSelectedDistrict: (filter, action) => {
      filter.districtId = action.payload.districtId;
    },
    filterSelectedStartDate: (filter, action) => {
      filter.startDate = action.payload.startDate;
    },
    filterSelectedEndDate: (filter, action) => {
      filter.endDate = action.payload.endDate;
    },
    filterSelectedDeviceType: (filter, action) => {
      filter.deviceType = action.payload.deviceType;
    },
    filterSelectedBrand: (filter, action) => {
      filter.brandId = action.payload.brandId;
    },
    filterSelectedDevice: (filter, action) => {
      filter.modelId = action.payload.deviceId;
    },
    filterSelectedClient: (filter, action) => {
      filter.clientId = action.payload.clientId;
    },
    filterSelectedDay: (filter, action) => {
      filter.dayId = action.payload.dayId;
    },
    filterSelectedTimeZone: (filter, action) => {
      filter.timeZone = action.payload.timeZone;
    },
    filterSortObject: (filter, action) => {
      filter.sortObject = action.payload.sortObject;
    },
    filterselectedPartType: (filter, action) => {
      filter.selectedPartType = action.payload.selectedPartType;
    },
  },
});

export const {
  filterSelectedCountry,
  filterSelectedProvince,
  filterSelectedCity,
  filterSelectedDistrict,
  filterSelectedStartDate,
  filterSelectedEndDate,
  filterSelectedDeviceType,
  filterSelectedBrand,
  filterSelectedDevice,
  filterSelectedClient,
  filterSelectedDay,
  filterSelectedTimeZone,
  filterSortObject,
  filterselectedPartType,
} = slice.actions;
export default slice.reducer;
