import { toast } from "react-toastify";

/* 
   Default "type" is always error only "message" parameter is passed in a function call,
   for any other types need to pass "message","position", 
   "type" and "icon" as parameter in function call
*/
export const ToastAlert = (data) => {
  let template = (
    <div className="toastifyContainer">
      <i
        className={`toastIconStyle + ${
          typeof data.type === "undefined" ? "ico-disapproved" : data.icon
        } `}
      ></i>
      <p className="toastifyTxt">{data.message}</p>
    </div>
  );
  let options = {
    position: typeof data.position === "undefined" ? "top-center" : data.position,
    autoClose: data.type === "success" ? true : false,
    closeOnClick: true,
  };
  if (data.type === "success") {
    toast.success(template, options);
  } else if (data.type === "info") {
    toast.info(template, options);
  } else if (data.type === "warning") {
    toast.warning(template, options);
  } else {
    toast.error(template, options);
  }
};

export default ToastAlert;
